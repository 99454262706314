import React, { useState, useEffect } from "react";

import "semantic-ui-css/semantic.min.css";
import { Checkbox, Divider, Form, Button } from "semantic-ui-react";
import Layout from "../../components/layout";

import trustData from "./data/trusts.json";
import tileData from "./data/tiles.json";
import pageData from "./data/pages.json";
import sectionsData from "./data/sections.json";
import pathwayData from "./data/pathways.json";
import junctionData from "./data/junctions.json";
import imageData from "./data/images.json";
import onboardingData from "./data/onboarding.json";

import { useDispatch, useSelector } from "react-redux";
import {
  addTrust,
  addTile,
  addPage,
  addSection,
  fetchSections,
  update_Section,
  fetchJunctionPages,
  fetchPages,
  fetchTiles,
  fetchPathways,
  fetchWellnessForms,
  fetchWellnessQuestions,
  fetchImages,
  add_junctionPage,
  addImage,
  addSystemInfo,
  removeTrust,
  removeTile,
  removePage,
  removeSection,
  removeSystemInfo,
} from "../../actions";

import {
  fetchAllTiles,
  fetchAllPages,
  fetchAllSections,
  fetchAllPathways,
  fetchAllJunctions,
  fetchAllImages,
  fetchAllOnboarding,
  fetchAllTrusts,
  addPathway,
} from "../../allData";

// import { withAuthenticator } from 'aws-amplify-react'
// import data2 from '../data/data.csv'

import Papa from "papaparse";

function Index() {
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const sections = useSelector((state) => state.sections);
  const user = useSelector((state) => state.user["custom:trust"]);

  const [all_pages, update_all_pages] = useState([]);
  const [all_tiles, update_all_tiles] = useState([]);
  const [all_sections, update_all_sections] = useState([]);

  const [all_onboarding, update_all_onboarding] = useState([]);

  const [index_start, update_index_start] = useState(0);
  const [index_finish, update_index_finish] = useState(499);
  // const [index_finish, update_index_finish] = useState(499);
  //const [rows, setRows] = React.useState([]);

  React.useEffect(() => {
    async function getData() {
      try {
        dispatch(fetchPathways(user["custom:trust"]));
        dispatch(fetchTiles(user["custom:trust"]));
        dispatch(fetchPages(user["custom:trust"]));
        dispatch(fetchSections(user["custom:trust"]));
        dispatch(fetchJunctionPages(user["custom:trust"]));
        dispatch(fetchWellnessForms(user["custom:trust"]));
        dispatch(fetchWellnessQuestions(user["custom:trust"]));
        dispatch(fetchImages());
      } catch (error) {
        console.log("error");
      }
    }
    //dispatch(fetchSections(user));

    try {
      getData();
    } catch (error) {
      console.log("error");
    }
  }, []); // [] means just do this once, after initial render

  // function fixButtonTitles() {
  //   console.log("we are here");
  //   console.log(sections);
  //   console.log(user);
  //   sections.map((section, index) => {
  //     console.log(index);
  //     if (
  //       section.type === "link" ||
  //       section.type === "cta" ||
  //       section.type === "info" ||
  //       section.type === "ext_link"
  //     ) {
  //       console.log(section);
  //       const params = {
  //         Item: {
  //           id: section.id,
  //           button_text: section.title,
  //         },
  //       };
  //       console.log(params);
  //       dispatch(update_Section(params));
  //     }
  //   });
  // }

  function upload(event, flow) {
    var data = ["asd"];
    const file = event.target.files[0];
    Papa.parse(file, {
      header: true,
      complete: function (results) {
        data = results.data;
        switch (flow) {
          case "Trusts":
            data.map((trust) => {
              console.log(trust);

              try {
                let new_survery_array = [];
                const survey_array = JSON.parse(trust["surveys (L)"]);
                survey_array.map((survey) => {
                  const params = {
                    completed: survey["M"].completed["BOOL"],
                    number_of_opens: survey["M"].number_of_opens["N"],
                    open_count: survey["M"].open_count["N"],
                    survey_id: survey["M"].survey_id["N"],
                    survey_link: survey["M"].survey_link["S"],
                    title: survey["M"].title["S"],
                  };
                  new_survery_array.push(params);
                });

                const is_active = JSON.parse(trust["active (BOOL)"]);
                const params = {
                  user_id: trust["user_id (S)"],
                  timestamp: parseInt(trust["timestamp (N)"]),
                  active: is_active,
                  image_url: trust["image_url (S)"],
                  name: trust["name (S)"],
                  surveys: JSON.stringify(new_survery_array),
                  trust_id: trust["trust_id (S)"],
                  trust_image: trust["trust_image (S)"],
                  user_name: trust["user_name (S)"],
                  version: 1,
                  supported_app_version: 1,
                };
                //console.log(params)
                dispatch(addTrust({ Item: params }));
              } catch (error) {
                console.log("error");
                console.log(error);
                console.log("this is the trust");
                console.log(trust);
              }
            });
            break;

          case "Tiles":
            console.log("Adding Tiles");
            data.map((tile) => {
              console.log(tile);
              return;
              try {
                console.log(tile);
                var is_restrict_to_admin = false;
                if (tile["restrict_to_admin (BOOL)"] !== "") {
                  is_restrict_to_admin = JSON.parse(
                    tile["restrict_to_admin (BOOL)"]
                  );
                }
                const is_index_number = JSON.parse(tile["index_number (N)"]);

                const params = {
                  user_id: tile["user_id (S)"],
                  user_name: tile["user_name (S)"],
                  tile_id: tile["tile_id (S)"],
                  title: tile["title (S)"],
                  destination: tile["destination (S)"],
                  destination_name: tile["destination_name (NULL)"],
                  index_number: is_index_number,
                  icon: "mental",
                  colour: tile["colour (S)"],
                  restrict_to_admin: is_restrict_to_admin,
                  timestamp: parseInt(tile["timestamp (N)"]),
                  junction_page_selected: false,
                };
                dispatch(addTile({ Item: params }));
              } catch (error) {
                console.log("error");
                console.log(error);
                console.log("this is the tile");
                console.log(tile);
              }
            });
            break;

          case "Pages":
            console.log("Adding Pages");
            data.map((page) => {
              try {
                // console.log(page)
                var is_restrict_to_admin = false;
                //console.log(page["restrict_to_admin (BOOL)"])
                if (
                  page["restrict_to_admin (BOOL)"] === true ||
                  page["restrict_to_admin (BOOL)"] === false
                ) {
                  is_restrict_to_admin = JSON.parse(
                    page["restrict_to_admin (BOOL)"]
                  );
                }
                const is_index_number = JSON.parse(page["index_number (N)"]);
                const params = {
                  user_id: page["user_id (S)"],
                  title: page["title (S)"],
                  index_number: is_index_number,
                  page_id: page["page_id (S)"],
                  timestamp: parseInt(page["timestamp (N)"]),
                  user_name: page["user_name (S)"],
                  restrict_to_admin: is_restrict_to_admin,
                };
                dispatch(addPage({ Item: params }));
              } catch (error) {
                console.log("error");
                console.log(error);
                console.log("this is the page");
                console.log(page);
              }
            });
            break;

          case "Sections":
            data.map((section) => {
              console.log(section);

              //console.log(JSON.parse(section))
              // var is_restrict_to_admin = false
              // if(section["restrict_to_admin (BOOL)"] !==  ""){
              //   is_restrict_to_admin = JSON.parse(section["restrict_to_admin (BOOL)"])
              // }
              try {
                const is_index_number = JSON.parse(section["index_number"]);
                const params = {
                  user_id: section["user_id"],
                  timestamp: parseInt(section["timestamp"]),
                  colour: section["colour"],
                  destination: section["destination"],
                  ext_link: section["ext_link"],
                  icon: section["icon"],
                  image_hash: section["image_hash"],
                  image_url: section["image_url"],
                  index_number: is_index_number,
                  original_image_key: section["original_image_key"],
                  section_id: section["section_id"],
                  text: section["text"],
                  title: section["title"],
                  type: section["type"],
                  user_name: section["user_name"],
                };
                console.log(params);
                dispatch(addSection({ Item: params }));
              } catch (error) {
                console.log("error");
                console.log(error);
                console.log("this is the section");
                console.log(section);
              }
            });
            break;
          default:
            break;
        }
      },
    });
  }

  async function getTrustsALL() {
    const asd = await fetchAllTrusts();
    console.log(asd);
  }

  async function getTilesALL() {
    const asd = await fetchAllTiles();
    update_all_tiles(asd);
    console.log(asd);
  }

  async function getPagesALL() {
    const asd = await fetchAllPages();
    update_all_pages(asd);
    console.log(asd);
  }

  async function getSectionsALL() {
    const asd = await fetchAllSections();
    update_all_sections(asd);
    console.log("getting all sections");
    console.log(asd);
    console.log(asd);
  }

  async function getPathwaysALL() {
    const asd = await fetchAllPathways();
    console.log(asd);
  }

  async function getJunctionsALL() {
    const asd = await fetchAllJunctions();
    console.log(asd);
  }

  async function getImagesALL() {
    const asd = await fetchAllImages();
    console.log(asd);
  }
  async function getOnboardingALL() {
    const asd = await fetchAllOnboarding();
    update_all_onboarding(asd);
    console.log(asd);
  }

  // ADDING INFORMATION

  async function addTrustsALL() {
    var data = trustData;
    data.map((entry) => {
      delete entry.createdAt;
      delete entry.updatedAt;
      delete entry.pathways;
      console.log(entry);
      dispatch(addTrust({ Item: entry }));
    });
  }

  async function addTilesALL() {
    var data = tileData;
    data.map((entry) => {
      console.log("ADDING TILE");
      delete entry.createdAt;
      delete entry.updatedAt;
      console.log(entry);
      dispatch(addTile({ Item: entry }));
    });
  }

  async function addPagesALL() {
    var data = pageData;
    data.map((entry, index) => {
      if (index >= index_start && index <= index_finish) {
        console.log(entry);
        console.log(index);
        delete entry.createdAt;
        delete entry.updatedAt;
        console.log(entry);
        dispatch(addPage({ Item: entry }));
      }
      return;
    });
    update_index_start(index_start + 500);
    update_index_finish(index_finish + 500);
  }

  async function addSectionssALL() {
    var data = sectionsData;
    data.map((entry, index) => {
      if (index >= index_start && index <= index_finish) {
        console.log(entry);
        console.log(index);
        delete entry.createdAt;
        delete entry.updatedAt;
        console.log(entry);
        dispatch(addSection(entry));
      }
      return;
    });
    update_index_start(index_start + 500);
    update_index_finish(index_finish + 500);
  }

  async function addPathwaysALL() {
    var data = pathwayData;
    data.map((entry) => {
      delete entry.createdAt;
      delete entry.updatedAt;
      console.log(entry);
      dispatch(addPathway(entry));
    });
  }

  async function addJunctionsALL() {
    var data = junctionData;
    data.map((entry) => {
      delete entry.createdAt;
      delete entry.updatedAt;
      console.log(entry);
      dispatch(add_junctionPage(entry));
    });
  }

  async function addImagesALL() {
    var data = imageData;
    data.map((entry) => {
      delete entry.createdAt;
      delete entry.updatedAt;

      delete entry.cached_image;
      delete entry.low_res_image_url;
      delete entry.tags;
      delete entry.user_id;

      console.log(entry);
      dispatch(addImage(entry));
    });
  }

  async function addOnboardingALL() {
    var data = onboardingData;
    data.map((entry) => {
      delete entry.createdAt;
      delete entry.updatedAt;
      console.log(entry);
      dispatch(addSystemInfo(entry));
    });
  }
  return (
    <Layout>
      <div>
        <Divider />
        <h2>Get data</h2>
        <Button onClick={() => getTrustsALL()}>Trusts</Button>
        <Button onClick={() => getTilesALL()}>Tiles</Button>
        <Button onClick={() => getPagesALL()}>Pages</Button>
        <Button onClick={() => getSectionsALL()}>Sections</Button>
        <Button onClick={() => getPathwaysALL()}>Pathways</Button>
        <Button onClick={() => getJunctionsALL()}>Junction Pages</Button>
        <Button onClick={() => getImagesALL()}>Images</Button>
        <Button onClick={() => getOnboardingALL()}>Onboarding</Button>
        <Divider />
        <Divider />
        <h2>Add data</h2>
        <div>Index Start: {index_start}</div>
        <div>Index Finish: {index_finish}</div>
        <Button primary onClick={() => addTrustsALL()}>
          Trusts
        </Button>
        <Button primary onClick={() => addTilesALL()}>
          Tiles
        </Button>
        <Button primary onClick={() => addPagesALL()}>
          Pages
        </Button>
        <Button primary onClick={() => addSectionssALL()}>
          Sections
        </Button>
        <Button primary onClick={() => addPathwaysALL()}>
          Pathways
        </Button>
        <Button primary onClick={() => addJunctionsALL()}>
          Junction Pages
        </Button>
        <Button primary onClick={() => addImagesALL()}>
          Images
        </Button>
        <Button primary onClick={() => addOnboardingALL()}>
          Onboarding
        </Button>
        <Divider />
        <Divider />
        <h2>Remove data</h2>
        <div>Index Start: {index_start}</div>
        <div>Index Finish: {index_finish}</div>
        <Button
          secondary
          onClick={() => {
            state.trusts.map((trust) => {
              dispatch(removeTrust(trust));
            });
          }}
        >
          Trusts
        </Button>
        <Button
          secondary
          onClick={() => {
            all_tiles.map((tile) => {
              dispatch(removeTile(tile));
            });
          }}
        >
          Tiles
        </Button>
        <Button
          secondary
          onClick={() => {
            all_pages.map((page) => {
              dispatch(removePage(page));
            });
          }}
        >
          Pages {all_pages.length}
        </Button>

        <Button
          secondary
          onClick={() => {
            all_sections.map((section) => {
              dispatch(removeSection(section.id));
            });
          }}
        >
          Sections {all_sections.length}
        </Button>
        <Button secondary onClick={() => addPathwaysALL()}>
          Pathways
        </Button>
        <Button secondary onClick={() => addJunctionsALL()}>
          Junction Pages
        </Button>
        <Button secondary onClick={() => addImagesALL()}>
          Images
        </Button>
        <Button
          secondary
          onClick={() => {
            all_onboarding.map((ob) => {
              dispatch(removeSystemInfo(ob.id));
            });
          }}
        >
          Onboarding {all_onboarding.length}
        </Button>
        <Divider />
        <div>Pages: {all_pages.length}</div>
        <div>Sections: {sectionsData.length}</div>
        <div>Pathways: {pathwayData.length}</div>
        <div>Junctions: {junctionData.length}</div>
        <div>Images: {imageData.length}</div>
        <div>Onboarding: {onboardingData.length}</div>
        <div>{JSON.stringify(all_tiles)}</div>
        <Form>
          <Form.Field>
            <h2>Trusts</h2>
            <input
              type="file"
              id={"cvs:tasks"}
              onChange={(e) => upload(e, "Trusts")}
              //style={{display:"none"}}
            />
          </Form.Field>

          <Form.Field>
            <h2>Tiles</h2>
            <input
              type="file"
              id={"cvs:tasks"}
              onChange={(e) => upload(e, "Tiles")}
              //style={{display:"none"}}
            />
          </Form.Field>

          <Form.Field>
            <h2>Pages</h2>
            <input
              type="file"
              id={"cvs:tasks"}
              onChange={(e) => upload(e, "Pages")}
              //style={{display:"none"}}
            />
          </Form.Field>

          <Form.Field>
            <h2>Sections</h2>
            <input
              type="file"
              id={"cvs:tasks"}
              onChange={(e) => upload(e, "Sections")}
              //style={{display:"none"}}
            />
          </Form.Field>
        </Form>
      </div>
      {/* <Divider />
      <Button onClick={() => fixButtonTitles()}>Fix Button Titles</Button> */}
    </Layout>
  );
}

export default Index;
